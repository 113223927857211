import React, { Component } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import AOS from "aos"
import "aos/dist/aos.css"
import NewsStrip from "../components/news-strip"
import arrowSvg from "../images/arrow-circle-right-solid.svg"

class BG2ServicePage extends Component {
  componentDidMount() {
    AOS.init()
    window.addEventListener("load", AOS.refresh)
    // @NOTE: Hack to force AOS to work :(
    window.setTimeout(AOS.refresh, 1000)
  }

  componentDidUpdate() {
    AOS.refresh()
  }

  render() {
    return (
      <Layout>
        <SEO
          title="BG2"
          description="View route information and buy your ticket for the BG2 service operated by Tetley's Coaches."
        />
        <div className="block-services inner-body-bg">
          <div className="body gutter page-content-parent">
            <div className="text-center">
              <h1 className="page-content-heading">BG2 service</h1>
              <p>Headingley - Bradford Grammar School</p>
              <p>Bradford Grammar School - Headingley</p>
              <p>For information on fares and passes please contact the school via email @ <a href="mailto:transport@bradfordgrammar.com">transport@bradfordgrammar.com</a></p>
            </div>
          </div>

          <div className="body gutter page-content-parent">
            <div className="page-content">
              <div className="page-content__text">
                <h2>Route</h2>
                <iframe
                  title="BG2 route map"
                  className="route-map"
                  src="https://www.google.com/maps/d/embed?mid=137gxyjhy_YeODGaTysxPAKXal31xjc_N&ehbc=2E312F"
                  width="100%"
                  height="480"
                ></iframe>

                <p>
                  <strong>AM Towards</strong> Bradford Grammar School
                </p>
                <p>
                Buckingham Road, Headingley Lane, Otley Road, Otley Old Road, Cookridge Lane, Green Lane, Wood Hill Road, Tinshill Road, Station Road, Brownberrie Lane, Layton Road, Town Street, Over Lane, Leeds road, Micklefield road, Micklefield Lane, Apperley Lane, Harrogate Road, New Line, Leeds Road, Town Lane, Highfield Road, Swain House Road, Kings Road, Bolton Lane, Canal Road, Frizinghall Road, School Bus Bays.
                </p>

                <p>
                  <strong>PM From</strong> Bradford Grammar School
                </p>
                <p>
                School Bus Bays, Frizinghall Road, Canal Road, Bolton Road, Kings Road, Swain House Road, Highfield Road, Town Lane, Leeds Road, New Line, Harrogate Road, Apperley Lane, Micklefield Lane, Micklefield Road, Leeds Road, Over lane, Town Street, Layton Road, Brownberrie Lane, Station Road, Tinshill Road, Wood hill Road, Green Lane, Cookridge Lane, Otley Old Road, Otley Road, Headingley Lane, Buckingham Road.
                </p>

                <h4>Bus stop information</h4>
                <ul>
                  <li>Most stops listed are located at designated public bus stops however, a small number of stops (* marked with an asterisk), are not located at public bus stops; where this is the case, the exact location is noted.</li>
                  <li>Pupils are not permitted to board the bus at any other location than the stops listed. Pupils must arrive at their AM stop at least 5 minutes ahead of the times shown; the bus will depart on time and will not wait.</li>
                  <li>AM and PM times may vary due to unforeseen circumstances e.g. traffic, roadworks etc. Should a bus be delayed by more than 10 minutes, the school will make every effort to contact parents however, it is recommended that parents contact the bus company directly for updates</li>
                </ul>
              </div>
              <div className="page-content__text">
                <h2>Timetable</h2>
                <table className="timetable" style={{ fontSize: '0.9em' }}>
                  <thead>
                    <tr>
                      <th colSpan="3">Morning</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Headingley</td>
                      <td>Buckingham Road * <span className="timetable-hint">Top of Buckingham road</span></td>
                      <td>0710</td>
                    </tr>
                    <tr>
                      <td>Headingley</td>
                      <td>A660 Otley Road <span className="timetable-hint">Bus stop between St Anne's Road and St Chad's Drive</span></td>
                      <td>0712</td>
                    </tr>
                    <tr>
                      <td>West Park</td>
                      <td>A660 Otley Road/Church Wood Avenue</td>
                      <td>0715</td>
                    </tr>
                    <tr>
                      <td>West Park</td>
                      <td>A660 Otley Road/Welburn Grove</td>
                      <td>0717</td>
                    </tr>
                    <tr>
                      <td>Lawnswood</td>
                      <td>Otley Old Road/New Adel Lane</td>
                      <td>0720</td>
                    </tr>
                    <tr>
                      <td>Cookridge</td>
                      <td>Otley Old Road/Tinshill Drive</td>
                      <td>0722</td>
                    </tr>
                    <tr>
                      <td>Cookridge</td>
                      <td>Green Lane/Kirkwood Drive</td>
                      <td>0725</td>
                    </tr>
                    <tr>
                      <td>Cookridge</td>
                      <td>Tinshill Road/Wood Hill Road</td>
                      <td>0728</td>
                    </tr>
                    <tr>
                      <td>Horsforth</td>
                      <td>Brownberrie Lane <span className="timetable-hint">Bus stop outside Brownberrie Lane Pre-School</span></td>
                      <td>0730</td>
                    </tr>
                    <tr>
                      <td>Horsforth</td>
                      <td>Brownberrie Lane/Southway</td>
                      <td>0732</td>
                    </tr>
                    <tr>
                      <td>Rawdon</td>
                      <td>Town Street/Carr Lane</td>
                      <td>0735</td>
                    </tr>
                    <tr>
                      <td>Rawdon</td>
                      <td>Emmot Arms pub / top of Over Lane</td>
                      <td>-</td>
                    </tr>
                    <tr>
                      <td>Rawdon</td>
                      <td>B6152 Micklefield Lane/King Street</td>
                      <td>0741</td>
                    </tr>
                    <tr>
                      <td>Apperley Bridge</td>
                      <td>A658 Apperley Lane/Cliffe Drive</td>
                      <td>0748</td>
                    </tr>
                    <tr>
                      <td>Apperley Bridge</td>
                      <td>A658 Apperley Lane/Woodlands Drive <span className="timetable-hint">Outside The Moody Cow Pub</span></td>
                      <td>0749</td>
                    </tr>
                    <tr>
                      <td>Greengates</td>
                      <td>A658 Harrogate Road/Carr Bottom Road</td>
                      <td>0755</td>
                    </tr>
                    <tr>
                      <td>Idle</td>
                      <td>A657 Leeds Road/Marlborough Road</td>
                      <td>0759</td>
                    </tr>
                    <tr>
                      <td>Idle</td>
                      <td>A657 Leeds Road/Cross Road</td>
                      <td>0801</td>
                    </tr>
                    <tr>
                      <td>Idle</td>
                      <td>A657 Leeds Road – Idle medical Centre</td>
                      <td>0804</td>
                    </tr>
                    <tr>
                      <td>Idle</td>
                      <td>Highfield Road / Green Lane <span className="timetable-hint">outside George Barker's</span></td>
                      <td>0806</td>
                    </tr>
                    <tr>
                      <td>Five Lane Ends</td>
                      <td>Kings Road (bus stop near Wrose Health Centre)</td>
                      <td>0810</td>
                    </tr>
                  </tbody>
                </table>

                <table className="timetable" style={{ fontSize: '0.9em' }}>
                  <thead>
                    <tr>
                      <th colSpan="3">Afternoon</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Five Lane Ends</td>
                      <td>Kings Road (bus stop near Wrose Health Centre)</td>
                      <td>1630</td>
                    </tr>
                    <tr>
                      <td>Idle</td>
                      <td>Highfield Road / Green Lane <span className="timetable-hint">outside George Barker's</span></td>
                      <td>1635</td>
                    </tr>
                    <tr>
                      <td>Idle</td>
                      <td>A657 Leeds Road – Idle medical Centre</td>
                      <td>1636</td>
                    </tr>
                    <tr>
                      <td>Idle</td>
                      <td>A657 Leeds Road/Cross Road</td>
                      <td>1639</td>
                    </tr>
                    <tr>
                      <td>Idle</td>
                      <td>A657 Leeds Road/Marlborough Road</td>
                      <td>1637</td>
                    </tr>
                    <tr>
                      <td>Greengates</td>
                      <td>A658 Harrogate Road/Carr Bottom Road</td>
                      <td>1641</td>
                    </tr>
                    <tr>
                      <td>Apperley Bridge</td>
                      <td>A658 Apperley Lane/Woodlands Drive <span className="timetable-hint">Outside The Moody Cow Pub</span></td>
                      <td>1647</td>
                    </tr>
                    <tr>
                      <td>Apperley Bridge</td>
                      <td>A658 Apperley Lane/Cliffe Drive</td>
                      <td>1648</td>
                    </tr>
                    <tr>
                      <td>Rawdon</td>
                      <td>B6152 Micklefield Lane/King Street</td>
                      <td>1655</td>
                    </tr>
                    <tr>
                      <td>Rawdon</td>
                      <td>Emmot Arms pub / top of Over Lane</td>
                      <td>-</td>
                    </tr>
                    <tr>
                      <td>Rawdon</td>
                      <td>Town Street/Carr Lane</td>
                      <td>1701</td>
                    </tr>
                    <tr>
                      <td>Horsforth</td>
                      <td>Brownberrie Lane/Southway</td>
                      <td>1704</td>
                    </tr>
                    <tr>
                      <td>Horsforth</td>
                      <td>Brownberrie Lane <span className="timetable-hint">Bus stop outside Brownberrie Lane Pre-School</span></td>
                      <td>1706</td>
                    </tr>
                    <tr>
                      <td>Cookridge</td>
                      <td>Tinshill Road/Wood Hill Road</td>
                      <td>1708</td>
                    </tr>
                    <tr>
                      <td>Cookridge</td>
                      <td>Green Lane/Kirkwood Drive</td>
                      <td>1711</td>
                    </tr>
                    <tr>
                      <td>Cookridge</td>
                      <td>Otley Old Road/Tinshill Drive</td>
                      <td>1714</td>
                    </tr>
                    <tr>
                      <td>Lawnswood</td>
                      <td>Otley Old Road/New Adel Lane</td>
                      <td>1716</td>
                    </tr>
                    <tr>
                      <td>West Park</td>
                      <td>A660 Otley Road/Welburn Grove</td>
                      <td>1719</td>
                    </tr>
                    <tr>
                      <td>West Park</td>
                      <td>A660 Otley Road/Church Wood Avenue</td>
                      <td>1721</td>
                    </tr>
                    <tr>
                      <td>Headingley</td>
                      <td>A660 Otley Road <span className="timetable-hint">Bus stop between St Anne's Road and St Chad's Drive</span></td>
                      <td>1724</td>
                    </tr>
                    <tr>
                      <td>Headingley</td>
                      <td>Buckingham Road * <span className="timetable-hint">Top of Buckingham road</span></td>
                      <td>1725</td>
                    </tr>
                  </tbody>
                </table>


              </div>
            </div>
          </div>
        </div>

        <NewsStrip />
      </Layout>
    )
  }
}

export default BG2ServicePage
